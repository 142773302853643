import React, { useState } from "react";
import tw, { css } from "twin.macro";

import * as T from "~components/styles/Typography.jsx";
import { GatsbyImage } from "gatsby-plugin-image";
import { getTextColorByBackgroundHex } from "../utils/helpers";

const Popup = ({ data }) => {
  const [isVisible, setIsVisible] = useState(data.active);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div
      css={[
        css`
          display: ${isVisible ? "flex" : "none"};
        `,
        tw`fixed items-center justify-center top-0 left-0 w-full h-full z-50`
      ]}
    >
      <div css={[tw`relative w-full h-full flex items-center justify-center`]}>
        <div css={[tw`absolute bg-black opacity-80 w-full h-full`]} />

        {/* Modal */}
        <div
          style={{
            backgroundColor: data.backgroundColor.hex,
            color: getTextColorByBackgroundHex(data.backgroundColor.hex),
            maxWidth: data.image ? "800px" : "550px"
          }}
          css={[
            css`
              text-align: ${data.image ? "left" : "center"};
            `,
            tw`relative w-[90%] p-8 rounded-[12px] overflow-hidden`
          ]}
        >
          {/* Graphic */}
          <div
            css={[
              tw`hidden sm:block absolute right-[-15%] bottom-[-5%] w-[50%] `
            ]}
          >
            <GatsbyImage image={data.image.asset.gatsbyImageData} />
          </div>

          {/* X Button */}
          <button
            onClick={handleClose}
            css={[
              tw`relative h-8 w-8 flex items-center justify-center absolute top-6 right-6`
            ]}
          >
            <svg
              viewBox="0 0 14 14"
              fill="none"
              css={[tw`w-full h-full`]}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="currentColor"
              />
            </svg>
          </button>

          {/* Main content */}
          <div css={[tw`grid grid-cols-1 gap-8 sm:w-[60%]`]}>
            <div css={[tw`w-[80px]`]}>
              <GatsbyImage image={data.logo.asset.gatsbyImageData} />
            </div>

            <div>
              <div css={[tw`mb-4`]}>
                <T.Head font="3">{data.heading}</T.Head>
              </div>

              <div>
                <T.Body font="2">{data.text}</T.Body>
              </div>
            </div>

            <div
              css={[
                css`
                  display: ${data.image ? "block" : "flex"};
                `,
                tw`justify-center`
              ]}
            >
              <a
                href={data.button.link}
                target="_blank"
                rel="noopener noreferrer"
                css={[
                  css`
                    background-color: ${data.buttonColor.hex};
                    color: ${getTextColorByBackgroundHex(data.buttonColor.hex)};
                  `,
                  tw`justify-center inline-block px-6 py-4 rounded-[4px]`
                ]}
              >
                <T.Head font="4">{data.button.text}</T.Head>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
