import React from "react";

const Spatula = () => (
  <svg viewBox="0 0 440 581" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_104_215"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="440"
      height="581"
    >
      <path
        d="M288.61 0.881014L0.168701 491.144L151.397 580.118L439.838 89.8545L288.61 0.881014Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_104_215)">
      <path
        d="M89.22 511.43C98.66 496.46 108.12 481.5 117.65 466.59C127.15 451.72 136.33 436.38 146.84 422.2C151.2 416.31 160.76 421.52 156.87 428.1C147.88 443.31 137.58 457.84 127.95 472.65C118.3 487.49 108.7 502.37 99.1 517.24C95.11 523.42 85.28 517.68 89.22 511.43ZM59.08 479.22C70 466.91 80.88 454.57 91.67 442.15C97.18 435.81 102.63 429.41 108.2 423.13C113.45 417.21 118.47 409.8 125.04 405.34C130.09 401.91 134.79 408.57 132.63 413.09C130.71 417.11 127.26 420.4 124.39 423.77C121.81 426.79 119.23 429.81 116.63 432.82C111.31 439.01 105.8 445.03 100.33 451.09C89.31 463.28 78.24 475.42 67.15 487.55C62.09 493.08 54.16 484.77 59.08 479.22ZM33.62 457.72C45.43 445.97 57.72 434.54 69.86 423.12C81.91 411.79 93.98 400.47 105.98 389.07C110.91 384.38 118.28 391.96 113.34 396.66C101.36 408.07 89.31 419.41 77.27 430.77C65.13 442.22 53.05 453.81 40.83 465.16C35.95 469.69 28.89 462.43 33.62 457.73M175.09 450.02C169.06 463.65 161.91 476.96 154.96 490.13C147.91 503.49 140.85 516.84 133.77 530.18C130.44 536.46 120.95 530.83 124.28 524.59C131.4 511.25 138.26 497.79 144.97 484.25C151.59 470.9 158.04 457.27 165.41 444.32C166.92 441.67 170.51 440.8 173.1 442.33C175.93 444 176.32 447.25 175.09 450.02ZM85.56 541.31C100.07 549.02 118.34 551.53 132.8 543.74C139.68 540.04 145.22 534.31 150.37 528.43C180.26 494.25 200.27 451.52 207.39 406.67C180.71 401.62 158.6 380.23 141.55 357.65C139.9 355.47 138.02 353.1 135.33 352.64C133.83 352.38 132.31 352.76 130.84 353.18C92.63 363.94 62.88 393.96 39.46 426.01C31.68 436.66 24.12 448.44 23.64 461.62C22.98 480 36.02 495.71 48.44 509.27C59.54 521.38 71.04 533.61 85.55 541.31"
        fill="#B5DBF0"
      />
      <path
        d="M27.22 462.89C43.25 441.49 61.11 421.5 81.15 403.78C100.28 386.85 120.34 371.76 141.81 358C141.72 357.88 141.62 357.76 141.53 357.64C139.88 355.46 138 353.09 135.31 352.63C133.81 352.37 132.29 352.76 130.82 353.17C92.6101 363.93 62.86 393.95 39.44 426C31.66 436.65 24.1 448.43 23.62 461.61C23.55 463.57 23.67 465.49 23.89 467.39C24.98 465.88 26.09 464.38 27.21 462.89"
        fill="#9ECFEB"
      />
      <path
        d="M378.8 140.97C386.39 141.3 393.96 138.54 399.95 133.87C405.93 129.2 410.37 122.7 413.14 115.63C414.96 110.96 416.1 105.94 415.64 100.95C415.19 95.96 413.02 91 409.14 87.83C407.71 86.66 405.89 85.71 404.07 86.06C401.45 86.56 400.07 89.38 399.15 91.88C396.31 99.57 394.1 107.53 390.45 114.87C386.79 122.21 381.45 129.05 374.05 132.6C372.68 133.26 371.21 133.83 370.16 134.93C369.11 136.03 368.63 137.88 369.57 139.07C370.24 139.92 371.38 140.2 372.45 140.37C376.14 140.95 379.95 140.82 378.81 140.97"
        fill="#0F4557"
      />
      <path
        d="M368.62 122.88C368.41 121.62 368.24 120.34 368.32 119.07C368.37 118.31 368.63 116.6 368.63 116.69C368.69 116.2 368.74 115.7 368.84 115.22C369.24 113.14 370.11 111.14 371.12 109.3C373 105.86 375.4 102.72 378.02 99.82C380.71 96.86 383.89 93.99 387.61 92.41C392.42 90.37 397.87 90.17 402.41 93.09C407.12 96.11 409.29 101.43 409.53 106.87C409.77 112.35 407.64 117.25 404.07 121.3C403.73 121.68 403.35 122 402.99 122.36C401.79 124.98 400.27 127.44 398.36 129.56C393.91 134.47 386.12 136.84 379.72 134.99C374.14 133.37 369.58 128.71 368.62 122.9M205.39 414.19C209.39 399.1 210.72 383.31 209.28 367.77C208.99 364.57 208.58 361.27 209.52 358.2C210.39 355.34 212.33 352.96 214.23 350.66C244.72 313.69 275.44 276.76 309.89 243.45C343.34 211.1 380.56 181.84 406.95 143.51C416.06 130.29 424.01 115.22 423.56 99.16C423.25 88.04 417.57 76 406.95 72.72C400.02 70.58 392.33 72.62 386.19 76.5C380.06 80.38 375.2 85.93 370.46 91.42C304.3 168.15 244.17 250.07 190.8 336.19C189.82 337.78 188.8 339.41 187.31 340.54C185.15 342.18 182.31 342.53 179.62 342.89C164.15 344.98 144.94 347.67 130.25 353.82C139.81 368.91 153.77 385.93 168.31 396.3C179.87 404.55 193.61 409.06 205.39 414.2"
        fill="#FF7854"
      />
      <path
        d="M138.54 362.41C167.1 367.98 193.17 350.62 210.83 329.77C220.67 318.16 228.52 305 236.13 291.87C244.16 278.02 252.05 264.09 260.52 250.5C277.57 223.14 295.36 196.21 313.95 169.87C332.77 143.21 352.09 116.99 372.42 91.46C375.68 87.36 379.01 83.32 382.32 79.26C377.93 82.79 374.15 87.1 370.46 91.38C304.3 168.11 244.17 250.03 190.8 336.15C189.81 337.74 188.8 339.37 187.31 340.5C185.15 342.14 182.31 342.49 179.62 342.85C164.15 344.93 144.94 347.63 130.25 353.78C132.04 356.61 134.03 359.5 136.1 362.41C136.85 362.26 137.66 362.24 138.54 362.41Z"
        fill="#ED523D"
      />
      <path
        d="M368.62 122.88C368.41 121.62 368.24 120.35 368.32 119.07C368.37 118.31 368.63 116.6 368.63 116.69C368.69 116.2 368.74 115.7 368.84 115.22C369.24 113.14 370.11 111.14 371.12 109.3C373 105.86 375.4 102.72 378.03 99.82C380.71 96.86 383.9 93.99 387.61 92.41C392.42 90.36 397.87 90.17 402.41 93.09C407.12 96.11 409.29 101.43 409.53 106.86C409.78 112.46 403.29 121.91 402.99 122.35C398.61 128.8 386.75 137.62 378.24 133.29C373.07 130.65 369.57 128.7 368.62 122.89M390.84 164.93C403.02 150.88 414.69 135.92 421.32 118.54C424.78 109.47 426.79 99.45 424.38 90.05C421.97 80.65 414.27 72.15 404.61 71.14C397.01 70.35 389.68 74.12 383.47 78.56C370.39 87.91 360.1 100.56 349.99 113.06C330.15 137.62 309.16 161.61 292 188.11C303.35 200.34 317.16 214.02 328.6 226.16C351.93 208.46 371.66 187.04 390.84 164.92"
        fill="#1F5466"
      />
      <path
        d="M324.45 164.87C341.66 142.08 355.75 116.07 377.66 97.23C386.87 89.31 397.02 82.98 408.43 78.78C410.83 77.9 413.4 77.08 416.07 76.37C412.84 73.53 408.93 71.58 404.62 71.14C397.02 70.35 389.69 74.12 383.48 78.56C370.4 87.91 360.11 100.56 350 113.06C330.16 137.62 309.17 161.61 292.01 188.11C293.74 189.98 295.55 191.89 297.38 193.81C307.74 185.56 316.49 175.41 324.46 164.86"
        fill="#0F4557"
      />
      <path
        d="M203.03 405.18C205.51 390 206.33 374.55 205.48 359.19C205.41 357.97 205.16 356.5 204.01 356.08C203.43 355.86 202.78 356 202.2 356.23C198.23 357.81 197.12 362.8 196.62 367.05C195 380.72 194.07 395.38 200.72 407.43C201.96 406.14 202.71 404.38 203.02 405.19"
        fill="#FF998C"
      />
      <path
        d="M223.9 330.35C253.57 294.29 285.58 260.15 319.68 228.24C319.5 226.56 317.51 225.58 315.85 225.87C314.19 226.15 312.83 227.3 311.56 228.41C278.01 257.74 247.61 290.67 221.04 326.45C219.12 329.03 217.2 332.67 219.19 335.21C222.33 333.95 224.98 331.5 223.9 330.36"
        fill="#FF998C"
      />
      <path
        d="M331.84 217.85C356.6 194.09 379.89 168.79 401.53 142.14C405.34 137.45 409.25 132.36 409.88 126.35C400.97 129.65 394.81 137.64 388.9 145.08C370.74 167.92 350.4 189.03 328.24 208.02C326.51 209.51 324.71 211.05 323.78 213.14C322.86 215.23 323.12 218.03 324.99 219.34C327.7 221.24 331.32 218.96 331.84 217.85Z"
        fill="#266178"
      />
      <path
        d="M81.5901 534.7C90.8201 539.56 101 542.6 111.38 543.63C115.94 544.08 120.99 543.99 124.53 541.07C123.73 538.56 120.8 537.52 118.24 536.9C108.42 534.51 98.2801 533.08 88.9701 529.11C71.7101 521.75 59.2201 506.6 47.4401 491.99C45.5601 493.29 45.1201 495.99 45.7801 498.18C46.4401 500.37 47.9901 502.15 49.4901 503.88C53.2701 508.22 57.0601 512.56 60.8401 516.9C67.5201 524.57 75.2001 531.36 81.5701 534.7"
        fill="#D6F0FF"
      />
      <path
        d="M163.1 505.09C179.78 481.28 191.93 454.3 198.7 426.03C199.09 424.42 199.45 422.74 199.04 421.14C198.63 419.54 197.18 418.08 195.54 418.23C193.85 418.39 192.76 420.06 192.03 421.59C189.77 426.34 188.53 431.49 187.09 436.56C183.44 449.48 178.49 462.04 172.34 473.98C167.47 483.43 161.79 492.66 159.36 503.01C159.13 503.99 159 505.21 159.79 505.84C161.56 505.23 163.13 504.02 163.09 505.09"
        fill="#D6F0FF"
      />
    </g>
  </svg>
);

export default Spatula;
