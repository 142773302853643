/* eslint-disable react/prop-types */
import React from "react";
import tw, { css } from "twin.macro";

import Image from "~components/Image.jsx";

const ImageSection = ({ data }) => {
  const { backgroundColor, image } = data;

  return (
    <section
      css={[
        css`
          background: ${backgroundColor?.hex || `white`};
        `,
        tw`relative block`
      ]}
    >
      <Image _css={[tw`w-full h-[56.2vh] md:h-auto`]} image={image} />
    </section>
  );
};

export default ImageSection;
