/* eslint-disable react/prop-types */

import React from "react";
import tw, { css, theme } from "twin.macro";

import Grid from "~components/styles/Grid.jsx";
import Image from "~components/Image.jsx";
import Go from "~components/Go.jsx";

import { mediaQuery } from "~utils/helpers";

const SponsorsSection = ({ data }) => {
  const { backgroundColor, sponsors } = data;

  const [firstSponsor, ...otherSponsors] = sponsors;

  return (
    <section
      css={[
        css`
          background: ${backgroundColor?.hex || `white`};
        `,
        tw`relative block`
      ]}
    >
      <Grid _css={[tw`py-8 md:py-16`]}>
        <Image
          _css={[
            tw`col-start-3 md:col-start-5 col-end-7 md:col-end-10 w-full mb-8`
          ]}
          image={firstSponsor.image}
          fit="contain"
        />

        <div
          css={[
            css`
              grid-template-columns: repeat(6, minmax(0, 1fr));

              ${mediaQuery(theme`screens.md.min`)} {
                margin-left: 0;
                margin-right: 0;

                grid-template-columns: repeat(9, minmax(0, 1fr));
              }
            `,
            tw`col-span-full md:col-start-14 md:col-span-9 w-full relative grid gap-[0.5rem]`
          ]}
        >
          {otherSponsors.map((sponsor) => (
            <Go
              to={sponsor?.link}
              _css={[
                tw`col-span-2 md:col-span-3 m-0`,
                !sponsor.link && tw`pointer-events-none`
              ]}
              newTab
            >
              <figure
                key={sponsor._key}
                css={[tw`w-full h-full max-h-[123px] flex`]}
              >
                <Image
                  image={sponsor.image}
                  fit="contain"
                  _css={[tw`h-auto`]}
                />
              </figure>
            </Go>
          ))}
        </div>
      </Grid>
    </section>
  );
};

export default SponsorsSection;
