/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import BannerSection from "~components/sections/BannerSection.jsx";
import HeadingSection from "~components/sections/HeadingSection.jsx";
import TextTwoColumn from "~components/sections/TextTwoColumn.jsx";
import Cards4Up from "~components/sections/Cards4Up.jsx";
import VideoSection from "~components/sections/VideoSection.jsx";
import SponsorsSection from "../components/sections/SponsorsSection";
import ImageSection from "../components/sections/ImageSection";
import MapSection from "../components/sections/MapSection";
import FormContactSection from "../components/sections/FormContactSection";
import FormAnnualReport from "../components/sections/FormAnnualReport";
import InstagramSection from "~components/sections/InstagramSection.jsx";
import CtaSection from "../components/sections/CtaSection";
import PartnersCarousel from "../components/sections/PartnersCarousel";
import Popup from "../components/Popup";

const IndexPage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityIndexPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <BannerSection data={cms.atfBanner} />

        <HeadingSection data={cms.headerSection} colorChange />

        <TextTwoColumn data={cms.textSectionTwoCol} />

        <CtaSection data={cms.ctaSection} />

        <Cards4Up cards={cms.cards4up.cards} />

        <VideoSection data={cms.videoURL} />

        <MapSection />

        <PartnersCarousel data={cms.sponsors} />

        <ImageSection data={cms.imageSection} />

        <FormContactSection />

        <FormAnnualReport />

        <InstagramSection />

        <Popup data={cms.popup} />
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    sanityIndexPage {
      title

      #

      atfBanner {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      headerSection {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      textSectionTwoCol {
        backgroundColor {
          hex
        }
        textColor {
          hex
        }
        columnOne
        columnTwo
      }

      #

      cards4up {
        cards {
          _key
          textColor {
            hex
            title
          }
          backgroundColor {
            title
            hex
          }
          image {
            altText
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          title
          body
          button {
            link
            text
          }
        }
      }

      #

      ctaSection {
        header
        body
        cta {
          link
          text
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        partnerGraphics
      }

      #

      videoURL {
        backgroundColor {
          hex
        }
        url
      }

      #

      sponsors {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        sponsors {
          _key
          image {
            altText
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }

      #

      imageSection {
        backgroundColor {
          hex
        }
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      popup {
        active
        backgroundColor {
          hex
        }
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        heading
        text
        button {
          link
          text
        }
        buttonColor {
          hex
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
