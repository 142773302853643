/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import Go from "~components/Go.jsx";
import { GatsbyImage } from "gatsby-plugin-image";
import * as T from "~components/styles/Typography.jsx";

import Button from "~components/Button.jsx";
import Spatula from "./heinz/Graphics/Spatula";
import Beans from "./heinz/Graphics/Beans";

const CtaSection = ({ data }) => {
  if (!data) return null;

  const { header, body, cta, image, partnerGraphics } = data;

  if (!header && !body && !cta) return null;

  //

  return (
    <section
      id="partner-news"
      css={[
        css`
          background: #00ae8c;
          color: white;
        `,
        tw`relative block md:pb-12`
      ]}
    >
      {partnerGraphics === "heinz" && (
        <>
          <div tw="absolute hidden transform scale-x-[-1] lg:block bottom-0 left-[-50px] w-[150px]">
            <Spatula />
          </div>
          <div tw="absolute hidden lg:block top-[20px] right-[-70px] w-[150px]">
            <Beans />
          </div>
        </>
      )}

      <Grid node="header" _css={[tw`pt-12 pb-12 `]}>
        <div tw="col-span-full md:col-span-11 md:col-start-2">
          {header && <T.Head font="3">{header}</T.Head>}

          {body && <T.Body font="1">{body}</T.Body>}
        </div>

        <div tw="col-span-full md:col-span-11 flex flex-col justify-between">
          {image && (
            <div tw="flex justify-center md:justify-end mb-4 mt-4 md:mt-0 ">
              <div tw="md:w-[80%] max-w-[500px]">
                <GatsbyImage image={image.asset.gatsbyImageData} />
              </div>
            </div>
          )}

          <div css={[tw`relative flex justify-end mt-5 md:mt-0 `]}>
            <Go to={cta.link} _css={[tw`w-full md:w-auto`]} newTab>
              <Button
                _css={[tw`w-full md:w-auto`]}
                text={cta.text}
                transparent
              />
            </Go>
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default CtaSection;
