import React from "react";
import * as styles from "./styles.module.scss";
import tw, { css } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";
import * as A from "~components/styles/Animations.jsx";
import Grid from "~components/styles/Grid.jsx";
import Marquee from "react-fast-marquee";
import { GatsbyImage } from "gatsby-plugin-image";
import Go from "~components/Go.jsx";
import Button from "~components/Button.jsx";

const PartnersCarousel = ({
  data: { textColor, backgroundColor, header, subheader, sponsors }
}) => {
  return (
    <section
      style={{ background: backgroundColor.hex, color: textColor.hex }}
      className={styles.container}
    >
      <Grid>
        <div
          className={styles.text}
          css={[
            css`
              ${A.Keyframes(`appear`, {
                delay: `0`,
                settings: `0.25s ${A.EASING_CUBIC} forwards`
              })}
            `,
            tw`relative py-14 md:py-20`
          ]}
        >
          <div className={styles.textContent}>
            <T.Body className={styles.heading} font="h3">
              {header}
            </T.Body>
            <T.Body className={styles.subheading} font="h5">
              {subheader}
            </T.Body>
          </div>
        </div>

        <div
          className={styles.buttonContainer}
          css={[
            css`
              ${A.Keyframes(`appear`, {
                delay: `0`,
                settings: `0.25s ${A.EASING_CUBIC} forwards`
              })}
            `,
            tw`relative pb-14 md:py-20`
          ]}
        >
          <Go to="/partners">
            <Button
              text="See All Partners"
              color="purple"
              _css={[
                css`
                  color: #6045e5 !important;
                  padding: 12px 16px 8px !important;
                `
              ]}
            />
          </Go>
        </div>
      </Grid>

      <Marquee gradient={false} speed={70}>
        {sponsors.map((sponsor) => (
          <div className={styles.marqueeItem} key={sponsor._key}>
            <div className={styles.imageContainer}>
              <GatsbyImage
                // style={{ width: "100%", height: "100%" }}
                image={sponsor.image.asset.gatsbyImageData}
              />
            </div>
          </div>
        ))}
      </Marquee>
    </section>
  );
};

export default PartnersCarousel;
