/* eslint-disable react/prop-types */

import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import tw, { css, theme } from "twin.macro";
import { useMediaQuery } from "~hooks";

import Go from "~components/Image.jsx";
import Grid from "~components/styles/Grid.jsx";
import Image from "~components/Image.jsx";
import * as T from "~components/styles/Typography.jsx";
// import ContactForm from "~components/ContactForm.jsx";

const FormContact = () => {
  const {
    sanityFormContact: {
      textColor,
      backgroundColor,
      formTextColor,
      formBackgroundColor,
      header,
      subheader,
      image
    }
  } = useStaticQuery(graphql`
    query {
      sanityFormContact {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        formTextColor {
          hex
        }
        formBackgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  const { isDesktop } = useMediaQuery();

  return (
    <section
      css={[
        css`
          background-color: ${backgroundColor?.hex || `white`};
          color: ${textColor?.hex || `black`};
        `,
        tw`relative`
      ]}
    >
      {/* <div
        css={[
          css`
            width: 50vw;
            background-color: ${formBackgroundColor?.hex || `black`};
          `,
          tw`h-full absolute top-0 right-0 bottom-0 hidden md:block`
        ]}
      /> */}

      {/*  */}

      <Grid>
        <div css={[css``, tw`col-span-24 relative`]}>
          <header tw="w-full sm:w-1/2 px-4 py-12 md:px-[60px] md:py-[72px]">
            <Link to="/lets-talk" tw="inline-block hover:underline mb-12">
              <T.Head level="2" font="3">
                {header}
              </T.Head>
            </Link>

            <T.Body font="h5">
              <span
                css={[
                  css`
                    a {
                      text-decoration: underline;
                    }
                  `
                ]}
                dangerouslySetInnerHTML={{ __html: subheader }}
              ></span>
            </T.Body>
          </header>

          {/* {isDesktop && (
            <figure
              css={[
                tw`relative block`,
                css`
                  width: 100%;
                  @media screen and (min-width: ${theme`screens.lg.min`}) {
                    width: 50vw;
                    transform: translate(calc(720px - 50vw));
                  }
                `
              ]}
            >
              <Image image={image} _css={[tw`w-full h-full`]} />
            </figure>
          )} */}
        </div>
        {/* <div
          css={[
            css`
              color: ${formTextColor?.hex || `white`};
              background-color: ${formBackgroundColor?.hex || `black`};
            `,
            tw`col-span-24 md:col-span-12 md:col-start-13 relative`
          ]}
        >
          <div
            css={[
              css`
                width: calc(100% + 2rem);
                left: -1rem;
                background-color: ${formBackgroundColor?.hex || `black`};
              `,
              tw`absolute top-0 bottom-0 md:hidden`
            ]}
          />
          <ContactForm textColor={formTextColor} />
        </div> */}
      </Grid>

      <Image
        _css={[tw`w-full h-full relative block object-cover`]}
        image={image}
      />
    </section>
  );
};

export default FormContact;
