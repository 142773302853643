import React from "react";

const Beans = () => (
  <svg viewBox="0 0 342 351" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_104_236)">
      <mask
        id="mask0_104_236"
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="342"
        height="347"
      >
        <path d="M341.15 2.5H0.860107V348.5H341.15V2.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_104_236)">
        <path
          d="M35.2201 300.08C39.5701 326.95 76.0001 356.86 102.41 346.33C128.82 335.8 132.76 326.18 164.23 341.69C195.7 357.21 236.5 341.71 246.65 321.52C256.79 301.33 251.23 289.32 274.55 279.02C297.87 268.72 314.52 262.81 312.44 234.99C310.35 207.17 313.79 211.57 327.28 184.14C340.76 156.71 352.57 137.95 321.35 115.42C290.13 92.89 260.94 128.5 247.43 103.87C233.93 79.24 256.01 93.9 242.76 62.26C229.51 30.61 215.93 46.4 192.53 34.94C169.13 23.48 183.07 12.34 159.19 4.80001C135.31 -2.72999 95.7101 8.01001 88.7501 39.61C81.7901 71.22 55.3601 49.1 32.9501 80.54C11.7901 110.23 7.86008 131.94 18.4101 148.24C28.9601 164.55 6.84008 170.09 1.77008 206.22C-3.28992 242.35 13.9601 234.06 29.7401 247.64C45.5201 261.22 30.8801 273.2 35.2301 300.07"
          fill="#CA3809"
        />
        <path
          d="M65.7902 207.12C52.1102 216.26 57.9002 235.19 72.4502 245.97C87.0002 256.75 101.89 263.75 119.16 252.46C136.43 241.17 131.03 219.09 119.66 217.44C108.29 215.79 100.01 226.1 87.4702 212.98C74.9302 199.86 65.7902 207.11 65.7902 207.11"
          fill="#DC784B"
        />
        <path
          d="M94.4502 232.49C91.3102 225.35 70.2502 207.63 64.3002 215.01C59.6302 225.12 62.8802 230.73 74.5102 238.32C86.1402 245.91 97.6002 239.62 94.4602 232.49"
          fill="#DC8E5A"
        />
        <path
          d="M119.66 217.45C116.51 216.99 113.6 217.46 110.77 218.09C117.58 224.55 124.59 234.18 110.94 247.09C99.3502 258.06 89.2802 256.05 77.9402 249.82C90.7702 258.22 104.06 262.35 119.17 252.47C136.44 241.18 131.03 219.1 119.67 217.46"
          fill="#DC5D26"
        />
        <path
          d="M73.9701 223.4C74.1901 220.2 68.8801 215.67 66.1201 217.82C64.2901 219.15 64.6201 221.56 65.0901 223.54C66.3201 228.76 73.6001 228.98 73.9701 223.4Z"
          fill="#E0AB70"
        />
        <path
          d="M167.62 251.47C166.48 230.87 144.65 224.51 137.54 233.53C130.43 242.55 135.21 254.88 117.59 259.18C99.9601 263.48 101.67 275.02 101.67 275.02C102.75 291.44 122.04 295.89 138.64 288.68C155.25 281.47 168.76 272.07 167.62 251.48"
          fill="#DC784B"
        />
        <path
          d="M137.97 262.88C130.22 262.03 104.34 271.41 107.76 280.26C114.18 289.35 120.66 289.35 133.05 283.08C145.44 276.8 145.72 263.74 137.97 262.89"
          fill="#DC8E5A"
        />
        <path
          d="M144.71 285.84C158.4 278.93 168.62 269.48 167.62 251.46C166.48 230.86 144.65 224.5 137.54 233.52C135.57 236.02 134.52 238.77 133.64 241.54C142.64 238.87 154.48 237.62 158.84 255.89C162.55 271.42 155.77 279.13 144.7 285.84"
          fill="#DC5D26"
        />
        <path
          d="M119.86 276.07C117.2 274.28 110.62 276.61 111.1 280.07C111.34 282.33 113.59 283.24 115.54 283.83C120.67 285.37 124.5 279.18 119.86 276.07Z"
          fill="#E0AB70"
        />
        <path
          d="M149.25 211.73C160.73 212.03 169.8 191.19 154.69 177.14C139.59 163.09 123.73 167.46 107.56 175.62C91.3901 183.77 82.4801 201.45 94.4101 212.78C94.4101 212.78 102.19 221.47 116.77 210.67C131.35 199.87 137.77 211.43 149.25 211.74"
          fill="#DC784B"
        />
        <path
          d="M108.28 183.5C95.5301 189 91.3901 193.99 94.2701 204.74C98.8801 213.03 122.65 199.13 126.95 192.63C131.25 186.13 121.03 177.99 108.28 183.5Z"
          fill="#DC8E5A"
        />
        <path
          d="M140.6 209.6C143.28 210.7 146.08 211.65 149.26 211.74C160.74 212.04 169.81 191.2 154.71 177.15C141.5 164.85 127.7 166.67 113.63 172.77C125.86 168.55 136.13 168.27 145.69 181.05C156.95 196.09 148.41 204.38 140.6 209.6Z"
          fill="#DC5D26"
        />
        <path
          d="M105.23 198.12C105.81 192.56 98.6002 191.54 96.5002 196.47C95.7002 198.35 94.9702 200.66 96.5502 202.29C98.9002 204.88 104.9 201.3 105.23 198.12Z"
          fill="#E0AB70"
        />
        <path
          d="M211.77 264.3C220.74 245.72 211.87 231.86 199.31 218.82C186.75 205.78 167.23 202.48 159.93 217.22C159.93 217.22 153.92 227.22 168.54 237.96C183.16 248.7 174.01 258.25 177.11 269.31C180.21 280.37 202.8 282.88 211.77 264.3Z"
          fill="#DC784B"
        />
        <path
          d="M191.99 221.83C182.97 211.27 176.98 208.78 167.56 214.71C161 221.56 181.29 240.17 188.78 242.36C196.26 244.55 201.02 232.38 191.99 221.82"
          fill="#DC8E5A"
        />
        <path
          d="M177.11 269.31C180.21 280.37 202.8 282.88 211.77 264.3C219.62 248.05 213.81 235.4 203.83 223.76C211.47 234.2 214.77 243.93 205.38 256.84C194.33 272.03 183.89 266.33 176.6 260.4C176.34 263.29 176.26 266.24 177.12 269.3"
          fill="#DC5D26"
        />
        <path
          d="M176.13 214.41C174.1 214.2 171.67 214.18 170.59 216.18C168.81 219.19 173.99 223.86 177.14 223.24C182.62 222.15 181.47 214.96 176.14 214.42"
          fill="#E0AB70"
        />
        <path
          d="M171.97 195.08C177.21 205.3 199.85 203.26 204.93 183.26C210.02 163.27 198.57 151.45 183.66 141.17C168.75 130.9 148.97 131.55 144.75 147.46C144.75 147.46 140.86 158.45 157.33 166.06C173.8 173.67 166.74 184.85 171.98 195.07"
          fill="#DC784B"
        />
        <path
          d="M177.09 145.59C166.15 137.04 159.79 135.8 151.73 143.49C146.67 151.51 170.26 165.7 178.03 166.36C185.8 167.02 188.03 154.14 177.09 145.6"
          fill="#DC8E5A"
        />
        <path
          d="M204.93 183.26C209.38 165.77 201.17 154.54 189.07 145.12C198.63 153.83 203.81 162.7 197.18 177.23C189.38 194.32 178.01 190.81 169.69 186.46C170.02 189.34 170.52 192.25 171.97 195.08C177.21 205.3 199.85 203.26 204.93 183.27"
          fill="#DC5D26"
        />
        <path
          d="M160.06 141.48C158.03 141.68 155.65 142.15 154.98 144.32C153.84 147.62 159.85 151.17 162.8 149.93C167.96 147.77 165.39 140.96 160.06 141.48Z"
          fill="#E0AB70"
        />
        <path
          d="M140.21 140.91C148.93 125.04 146.29 105.42 130.04 102.83C130.04 102.83 118.71 100.06 112.79 117.22C106.87 134.37 95.04 128.47 85.4 134.71C75.76 140.95 80.07 163.27 100.47 166.32C120.87 169.37 131.48 156.79 140.2 140.92"
          fill="#DC784B"
        />
        <path
          d="M134.7 109.37C126.21 105.14 114.47 130.04 114.59 137.84C114.72 145.64 127.75 146.56 135.15 134.81C142.55 123.06 143.15 116.61 134.69 109.37"
          fill="#DC8E5A"
        />
        <path
          d="M93.75 131.57C90.91 132.18 88.08 132.98 85.4 134.7C75.76 140.94 80.07 163.26 100.47 166.31C118.32 168.98 128.67 159.68 136.82 146.69C129.12 157.08 120.81 163.12 105.69 157.99C87.9 151.95 90.25 140.28 93.74 131.57"
          fill="#DC5D26"
        />
        <path
          d="M137.54 117.46C137.14 115.46 136.43 113.14 134.21 112.69C130.81 111.89 127.88 118.22 129.41 121.04C132.08 125.95 138.6 122.71 137.54 117.46Z"
          fill="#E0AB70"
        />
        <path
          d="M261.23 201.54C269.95 185.67 267.31 166.05 251.06 163.46C251.06 163.46 239.73 160.69 233.81 177.85C227.89 195 216.06 189.1 206.42 195.34C196.78 201.58 201.09 223.9 221.49 226.95C241.89 230 252.5 217.42 261.22 201.55"
          fill="#DC784B"
        />
        <path
          d="M255.72 170.01C247.23 165.78 235.48 190.68 235.61 198.48C235.74 206.28 248.77 207.2 256.17 195.45C263.57 183.7 264.17 177.25 255.71 170.01"
          fill="#DC8E5A"
        />
        <path
          d="M214.78 192.2C211.95 192.81 209.11 193.61 206.43 195.33C196.79 201.57 201.1 223.89 221.5 226.94C239.35 229.61 249.7 220.31 257.85 207.32C250.14 217.71 241.84 223.75 226.72 218.62C208.93 212.58 211.28 200.91 214.77 192.2"
          fill="#DC5D26"
        />
        <path
          d="M258.56 178.09C258.16 176.09 257.45 173.77 255.23 173.32C251.83 172.52 248.9 178.85 250.43 181.67C253.1 186.58 259.62 183.34 258.56 178.09Z"
          fill="#E0AB70"
        />
        <path
          d="M240.8 140.14C240.71 119.51 226.67 110.94 209.66 104.73C192.65 98.52 173.66 104.11 173.56 120.56C173.56 120.56 172.55 132.18 190.4 135.42C208.25 138.67 204.21 151.25 211.84 159.84C219.47 168.42 240.88 160.77 240.8 140.14Z"
          fill="#DC784B"
        />
        <path
          d="M204.4 110.65C191.66 105.12 185.19 105.51 179.32 114.96C176.43 124 202.83 131.82 210.51 130.51C218.2 129.2 217.13 116.17 204.39 110.64"
          fill="#DC8E5A"
        />
        <path
          d="M231.78 136.24C228.51 154.74 216.62 154.19 207.48 152.06C208.52 154.77 209.73 157.46 211.85 159.83C219.48 168.41 240.89 160.76 240.81 140.13C240.73 122.08 229.98 113.26 215.9 107.18C227.34 113.22 234.57 120.51 231.79 136.23"
          fill="#DC5D26"
        />
        <path
          d="M186.89 110.93C184.98 111.63 182.79 112.68 182.68 114.95C182.4 118.43 189.11 120.36 191.66 118.42C196.11 115.04 191.92 109.08 186.89 110.93Z"
          fill="#E0AB70"
        />
        <path
          d="M205.88 97.17C217.13 94.85 221.21 72.49 203.3 62.25C185.39 52.01 170.94 59.89 157.06 71.51C143.18 83.13 138.52 102.37 152.72 110.69C152.72 110.69 162.27 117.38 174.01 103.54C185.75 89.7 194.63 99.49 205.88 97.17Z"
          fill="#DC784B"
        />
        <path
          d="M179.82 83.65C182.53 76.34 170.72 70.75 159.56 79.01C148.4 87.27 145.5 93.07 150.76 102.88C157.14 109.9 177.11 90.96 179.82 83.64"
          fill="#DC8E5A"
        />
        <path
          d="M196.96 97.06C199.82 97.52 202.76 97.81 205.88 97.17C217.13 94.85 221.21 72.49 203.3 62.25C187.63 53.29 174.62 58.2 162.31 67.35C173.26 60.46 183.19 57.84 195.41 68.11C209.8 80.19 203.38 90.21 196.96 97.07"
          fill="#DC5D26"
        />
        <path
          d="M159.91 93.94C159.2 88.39 151.95 89.05 151.04 94.33C150.69 96.34 150.5 98.76 152.41 99.98C155.29 101.96 160.31 97.12 159.91 93.94Z"
          fill="#E0AB70"
        />
        <path
          d="M55.44 212.28C58.69 194.43 71.27 198.47 79.86 190.83C88.44 183.2 80.79 161.79 60.16 161.88C39.53 161.96 30.96 176.01 24.75 193.02C18.54 210.03 24.13 229.02 40.58 229.12C40.58 229.12 52.2 230.14 55.44 212.28Z"
          fill="#DC784B"
        />
        <path
          d="M50.5302 192.16C49.2202 184.47 36.1902 185.54 30.6602 198.28C25.1302 211.02 25.5202 217.49 34.9802 223.35C44.0202 226.24 51.8402 199.85 50.5302 192.16Z"
          fill="#DC8E5A"
        />
        <path
          d="M72.0802 195.2C74.7902 194.16 77.4802 192.95 79.8502 190.83C88.4302 183.2 80.7802 161.79 60.1502 161.88C42.1002 161.96 33.2802 172.71 27.2002 186.79C33.2402 175.35 40.5302 168.12 56.2502 170.9C74.7502 174.17 74.2002 186.06 72.0702 195.2"
          fill="#DC5D26"
        />
        <path
          d="M38.4402 211.02C35.0602 206.57 29.1002 210.76 30.9502 215.79C31.6502 217.71 32.7002 219.89 34.9702 220C38.4502 220.28 40.3802 213.57 38.4402 211.02Z"
          fill="#E0AB70"
        />
        <path
          d="M115.23 308.72C115.23 308.72 119.22 297.76 102.82 290.01C86.42 282.25 93.58 271.14 88.42 260.88C83.27 250.62 60.61 252.46 55.35 272.41C50.09 292.36 61.44 304.28 76.26 314.68C91.08 325.08 110.87 324.6 115.23 308.73"
          fill="#DC784B"
        />
        <path
          d="M82.1102 289.54C74.3502 288.82 72.0002 301.67 82.8802 310.31C93.7502 318.95 100.1 320.25 108.22 312.63C113.35 304.65 89.8802 290.26 82.1202 289.54"
          fill="#DC8E5A"
        />
        <path
          d="M90.6201 269.51C90.3201 266.62 89.8401 263.71 88.4201 260.87C83.2701 250.61 60.6101 252.45 55.3501 272.4C50.7501 289.85 58.8601 301.16 70.8801 310.68C61.3901 301.89 56.2901 292.97 63.0501 278.51C70.9901 261.49 82.3401 265.09 90.6201 269.52"
          fill="#DC5D26"
        />
        <path
          d="M104.98 311.78C106.15 308.49 100.17 304.89 97.2102 306.1C92.0402 308.22 94.5402 315.05 99.8802 314.57C101.91 314.39 104.3 313.94 104.98 311.78Z"
          fill="#E0AB70"
        />
        <path
          d="M222.43 283.33C222.43 283.33 214.93 274.4 200.02 284.73C185.1 295.06 179.06 283.3 167.59 282.63C156.13 281.96 146.4 302.51 161.04 317.03C175.69 331.56 191.68 327.69 208.1 320.05C224.52 312.42 234 295.04 222.43 283.33Z"
          fill="#DC784B"
        />
        <path
          d="M222.31 291.36C217.96 282.93 193.77 296.06 189.26 302.42C184.75 308.78 194.71 317.24 207.63 312.14C220.55 307.04 224.85 302.2 222.31 291.36Z"
          fill="#DC8E5A"
        />
        <path
          d="M176.17 285.03C173.52 283.85 170.76 282.81 167.58 282.62C156.11 281.95 146.39 302.49 161.03 317.02C173.84 329.73 187.69 328.36 201.95 322.71C189.59 326.53 179.32 326.49 170.17 313.41C159.4 298.02 168.2 290 176.17 285.04"
          fill="#DC5D26"
        />
        <path
          d="M219.95 293.74C217.68 291.08 211.58 294.46 211.15 297.63C210.4 303.17 217.57 304.42 219.82 299.55C220.68 297.7 221.48 295.41 219.96 293.73"
          fill="#E0AB70"
        />
        <path
          d="M72.6601 136.85C80.2701 120.38 91.4501 127.44 101.67 122.21C111.89 116.97 109.85 94.33 89.8501 89.24C69.8601 84.15 58.0401 95.61 47.7601 110.51C37.4901 125.42 38.1401 145.2 54.0501 149.43C54.0501 149.43 65.0401 153.32 72.6501 136.85"
          fill="#DC784B"
        />
        <path
          d="M72.9401 116.14C73.6001 108.37 60.7201 106.14 52.1801 117.08C43.6401 128.02 42.3901 134.39 50.0801 142.44C58.1001 147.5 72.2901 123.91 72.9501 116.14"
          fill="#DC8E5A"
        />
        <path
          d="M93.05 124.48C95.93 124.16 98.84 123.65 101.67 122.2C111.89 116.96 109.85 94.32 89.86 89.24C72.37 84.79 61.14 93 51.72 105.1C60.43 95.53 69.3 90.36 83.82 96.99C100.91 104.79 97.4 116.16 93.05 124.48Z"
          fill="#DC5D26"
        />
        <path
          d="M56.51 131.38C54.35 126.22 47.54 128.79 48.06 134.12C48.26 136.15 48.73 138.53 50.9 139.2C54.2 140.34 57.75 134.33 56.51 131.38Z"
          fill="#E0AB70"
        />
        <path
          d="M139.56 76.48C142.65 58.6 155.27 62.53 163.79 54.82C172.31 47.11 164.47 25.77 143.85 26.03C123.22 26.29 114.77 40.41 108.7 57.47C102.64 74.53 108.39 93.47 124.85 93.43C124.85 93.43 136.48 94.35 139.57 76.47"
          fill="#DC784B"
        />
        <path
          d="M134.47 56.4C133.09 48.72 120.08 49.9 114.66 62.69C109.24 75.47 109.68 81.94 119.19 87.73C128.25 90.54 135.85 64.08 134.47 56.4Z"
          fill="#DC8E5A"
        />
        <path
          d="M156.05 59.25C158.75 58.19 161.43 56.95 163.79 54.82C172.3 47.11 164.47 25.77 143.85 26.04C125.8 26.27 117.07 37.1 111.11 51.24C117.05 39.75 124.28 32.45 140.02 35.09C158.55 38.2 158.1 50.09 156.05 59.26"
          fill="#DC5D26"
        />
        <path
          d="M122.55 75.37C119.13 70.95 113.21 75.19 115.1 80.2C115.82 82.11 116.89 84.29 119.15 84.37C122.64 84.62 124.51 77.9 122.55 75.36"
          fill="#E0AB70"
        />
        <path
          d="M279.74 255.48C292.27 242.41 294.8 222.78 279.77 216.06C279.77 216.06 269.55 210.45 259.39 225.49C249.23 240.53 239.33 231.76 228.41 235.3C217.48 238.83 215.87 261.51 234.79 269.73C253.71 277.95 267.21 268.55 279.74 255.48Z"
          fill="#DC784B"
        />
        <path
          d="M282.58 223.59C275.47 217.31 257.69 238.32 255.79 245.89C253.9 253.46 266.25 257.72 276.44 248.29C286.63 238.85 288.88 232.78 282.58 223.6"
          fill="#DC8E5A"
        />
        <path
          d="M237.28 234.43C234.38 234.29 231.43 234.32 228.41 235.3C217.48 238.83 215.87 261.51 234.79 269.73C251.34 276.93 263.75 270.63 274.98 260.19C264.85 268.23 255.26 271.92 241.99 263.05C226.37 252.61 231.66 241.95 237.29 234.43"
          fill="#DC5D26"
        />
        <path
          d="M283.23 232.14C283.36 230.1 283.28 227.68 281.25 226.67C278.17 225.01 273.71 230.37 274.46 233.49C275.77 238.93 282.9 237.49 283.24 232.14"
          fill="#E0AB70"
        />
        <path
          d="M311.82 184.68C320.04 165.76 310.64 152.26 297.57 139.73C284.5 127.2 264.87 124.67 258.15 139.69C258.15 139.69 252.55 149.92 267.58 160.07C282.62 170.22 273.85 180.12 277.39 191.05C280.92 201.98 303.6 203.59 311.82 184.67"
          fill="#DC784B"
        />
        <path
          d="M290.37 143.03C280.94 132.84 274.86 130.59 265.68 136.89C259.4 144 280.41 161.78 287.98 163.68C295.54 165.57 299.81 153.22 290.38 143.03"
          fill="#DC8E5A"
        />
        <path
          d="M277.39 191.05C280.92 201.98 303.6 203.59 311.82 184.67C319.01 168.12 312.72 155.72 302.28 144.48C310.32 154.61 314.01 164.2 305.14 177.47C294.7 193.09 284.04 187.8 276.52 182.18C276.38 185.08 276.41 188.02 277.39 191.05Z"
          fill="#DC5D26"
        />
        <path
          d="M274.23 136.24C272.19 136.11 269.77 136.19 268.76 138.22C267.1 141.3 272.47 145.76 275.58 145.01C281.02 143.7 279.57 136.57 274.23 136.23"
          fill="#E0AB70"
        />
        <path
          d="M32.9001 116.53C34.5401 110.79 52.2301 82.48 33.1201 90.49C28.9101 95.02 26.6401 100.63 24.1001 106.13C20.8201 113.25 17.2301 126.43 20.9901 134.12C22.2101 136.62 25.9301 141.44 29.1801 139.15C29.5101 138.92 32.1501 119.18 32.9101 116.53"
          fill="#DC5D26"
        />
        <path
          d="M120.5 20.83C123.36 13.86 108.7 18.6 106.63 21C102.89 24.92 99.85 29.05 98.08 34.22C97.04 37.26 96.1 40.39 95.31 43.54C94.28 47.61 93.03 52.92 93.72 57.11C95.38 67.09 109.73 32.95 110.09 32.46C112.95 28.52 118.73 25.14 120.49 20.84"
          fill="#DC5D26"
        />
        <path
          d="M231.57 76.05C237.17 78.34 232.96 67.18 231.19 65.23C227.15 60.77 223.37 55.46 218.48 51.87C213.44 48.17 185.99 32.42 180.02 39.35L181.61 40.32C186.5 48.97 198.74 47.77 206.8 52.31C211.25 54.82 214.93 58.5 218.79 61.82C222.55 65.05 227.75 74.48 231.57 76.04"
          fill="#DC5D26"
        />
        <path
          d="M24.46 174.64C25.72 169.65 21.25 169.28 19.82 173.47C8.29998 190.95 -4.99002 224.9 20.66 236.74C24.12 238.34 29.19 241.88 33.13 241.01C36.03 240.37 35.81 237.26 33.8 235.54C27.47 230.09 15.62 227.5 14.06 218.15C11.35 201.95 20.76 189.33 24.46 174.64Z"
          fill="#DC5D26"
        />
        <path
          d="M48.2201 294.75C47.9501 291.13 48.0501 286.22 46.8801 282.7C46.1601 280.54 45.3601 276.85 45.2001 274.8C45.0101 272.21 41.5301 276.51 41.2801 277.48C36.2301 311.47 52.2701 327.83 80.5701 338.73C90.9901 342.74 98.3301 343.69 108.01 337.32C111.55 334.99 105.82 330.84 103.58 330.28C98.6001 329.03 92.5801 329.95 87.4601 329.32C78.9901 328.28 68.5601 323.54 62.1001 317.99C55.3401 312.18 48.9101 304.06 48.2101 294.74"
          fill="#DC5D26"
        />
        <path
          d="M225.56 333.24C228.18 331.67 231.39 326.46 231.59 323.42C231.73 321.37 227.94 321 226.78 321.7C217.68 327.13 207.56 332.83 196.54 334.9C187.13 336.67 160.69 328.91 160.44 329.02C158.71 329.74 162.53 332.64 163.34 332.97C173.54 334.55 186.2 341.38 196.76 341.4C202.51 341.41 220.67 336.18 225.56 333.25"
          fill="#DC5D26"
        />
        <path
          d="M270.46 274.71C276.7 271.82 317.54 261.44 306.18 247.83C301.49 242.21 294 258.34 290.27 261.25C282.42 267.38 265.1 269.66 254.17 276.19C249.77 278.82 247.3 295.99 252.83 289.56C256.81 282.45 263.24 278.04 270.45 274.71"
          fill="#DC5D26"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_104_236">
        <rect
          width="341"
          height="350"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Beans;
