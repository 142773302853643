/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import tw, { css, theme } from "twin.macro";
import { useMediaQuery } from "~hooks";
import Grid from "~components/styles/Grid.jsx";
import Go from "~components/Go.jsx";
import Button from "~components/Button.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Image from "~components/Image.jsx";

import { lowerCaseNoSpaces } from "~utils/helpers";

const Cards4Up = ({ cards }) => {
  const { isDesktop } = useMediaQuery();

  const [expanded, setExpanded] = useState(null);

  const onCardClick = (id) => {
    setExpanded(id);
  };

  //

  return (
    <section css={[css``, tw`relative block`]}>
      {/* // background grid haxx // */}
      <div
        css={[
          css`
            width: 50vw;
            background: ${cards[0]?.backgroundColor?.hex || `white`};
          `,
          tw`h-full absolute top-0 left-0 bottom-0 hidden md:block`
        ]}
      />
      <div
        css={[
          css`
            width: 50vw;
            background: ${cards[cards.length - 1]?.backgroundColor?.hex ||
            `white`};
          `,
          tw`h-full absolute top-0 right-0 bottom-0 hidden md:block`
        ]}
      />

      {/* // */}

      {isDesktop ? (
        <Grid node="ul">
          {cards.map((card) => (
            <Card
              key={card._key}
              id={card.id}
              background={card.backgroundColor}
              font={card.textColor}
              title={card.title}
              body={card.body}
              button={card.button}
              image={card.image}
            />
          ))}
        </Grid>
      ) : (
        <ul tw="relative w-full">
          {cards.map((card) => (
            <AccordionCard
              key={card._key}
              id={card._key}
              expanded={expanded}
              background={card.backgroundColor}
              font={card.textColor}
              title={card.title}
              body={card.body}
              button={card.button}
              image={card.image}
              onClick={onCardClick}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

export default Cards4Up;

const AccordionCard = ({
  id,
  expanded,
  background,
  font,
  title,
  body,
  button,
  onClick
}) => {
  const cardClick = () => {
    if (expanded === id) {
      onClick(null);
    } else {
      onClick(id);
    }
  };

  return (
    <li
      css={[
        css`
          background: ${background?.hex || `white`};
          color: ${font?.hex || `black`};
        `
      ]}
    >
      <article>
        <header
          css={[
            tw`w-full flex items-center justify-between px-4 py-6 cursor-pointer`
          ]}
          onClick={cardClick}
        >
          <T.Head level="3" font="4" _css={[tw``]}>
            {title}
          </T.Head>

          {expanded === id ? (
            <Icon.Line color={lowerCaseNoSpaces(font.title)} />
          ) : (
            <Icon.Cross
              color={lowerCaseNoSpaces(font.title)}
              _css={[
                css`
                  transform: rotate(45deg);
                `
              ]}
            />
          )}
        </header>
        {expanded === id && (
          <div tw="px-4 pb-6">
            <T.Body font="1" _css={[tw`mb-8`]}>
              {body}
            </T.Body>
            <Go to={button.link}>
              <Button
                color={lowerCaseNoSpaces(font.title)}
                text={button.text}
                transparent
                _css={[tw`w-full`]}
              />
            </Go>
          </div>
        )}
      </article>
    </li>
  );
};

const Card = ({ background, font, title, body, button, image }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <li
      css={[
        css`
          background: ${background?.hex || `white`};
          color: ${font?.hex || `black`};
        `,
        tw`relative block col-span-6`
      ]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        css={[
          tw`w-full h-full flex items-center justify-center p-6 pointer-events-none`,
          css`
            background: ${background?.hex || `white`};
            color: ${font?.hex || `black`};
          `
        ]}
      >
        <Image image={image} />
      </div>

      <article
        css={[
          tw`absolute w-full h-full flex flex-col justify-between top-0 right-0 bottom-0 left-0 p-6 transition-opacity duration-300`,
          hovered
            ? tw`opacity-100 pointer-events-auto`
            : tw`opacity-0 pointer-events-none`,
          css`
            background: ${background?.hex || `white`};
            color: ${font?.hex || `black`};
          `
        ]}
      >
        <header>
          <T.Head level="3" font="4" _css={[tw`mb-4`]}>
            {title}
          </T.Head>
          <T.Body font="1" _css={[tw``]}>
            {body}
          </T.Body>
        </header>

        <Go to={button.link}>
          <Button
            color={lowerCaseNoSpaces(font.title)}
            text={button.text}
            transparent
            _css={[tw`w-full`]}
          />
        </Go>
      </article>
    </li>
  );
};
